@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply scroll-smooth;
  }
}

@keyframes move-x {
  0% {
    transform: translateX(var(--move-x-from));
  }
  100% {
    transform: translateX(var(--move-x-to));
  }
}


/* Custom CSS */

.fixed-backdrop {
  backdrop-filter: blur(4px); /* Modern */
  -webkit-backdrop-filter: blur(4px); /* Safari */
  background-color: rgba(255, 255, 255, 0.1); /* Fallback */
}